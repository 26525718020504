import * as React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import App from "./App"

const appLanguage = typeof window !== "undefined" ? new URLSearchParams(window.location.search).get('lang') ?? 'fi' : 'fi';

const SanapeliPage = () => (
    <Layout>
        <SEO title="Sanapeli" description={`Kouluruoka.fi:n sanapeli ${appLanguage == 'en' ? 'englanniksi' : 'suomeksi'}`} />
        <App lang={appLanguage} />
    </Layout>
)

export default SanapeliPage
